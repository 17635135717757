import React from "react";
import * as prismic from "@prismicio/client";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import styles from "./SectionIntro.module.css";
import { AppConfig } from "@/utils/AppConfig";
import FadeInWhenVisible from "@/components/FadeInWhenVisible/FadeInWhenVisible";
/**
 * Props for `SectionIntro`.
 */
export type SectionIntroProps = SliceComponentProps<Content.SectionIntroSlice>;

/**
 * Component for "SectionIntro" Slices.
 */
const SectionIntro = ({ slice }: SectionIntroProps): JSX.Element => {
  return (
    <Box width="100%" background="brand.white">
      <FadeInWhenVisible>
        <Box
          className={["container", styles.sectionIntro__container].join(" ")}
        >
          {/* <Box className="container" marginBottom="-50px!important"> */}
          {slice.primary.showlogo && (
            <Flex textAlign={"center"} justifyContent={"center"}>
              <Image
                opacity={0.3}
                width="100px"
                marginBottom="40px"
                className={styles.logo}
                src="/eca-logo.png"
                alt={`${AppConfig.site_name} Logo`}
              />
            </Flex>
          )}

          <Text
            textStyle="h2"
            as="h2"
            className={styles.heading}
            textAlign="center"
            marginBottom="10px"
          >
            {prismic.asText(slice.primary.title)}
          </Text>

          <Text
            textStyle="p"
            as="p"
            textAlign="center"
            fontWeight="bold"
            maxWidth="900px"
            marginBottom="0"
          >
            {prismic.asText(slice.primary.subtitle)}
          </Text>
        </Box>
      </FadeInWhenVisible>
    </Box>
  );
};

export default SectionIntro;
